import { useLocalStorage } from '@vueuse/core'
import {
  STORAGE_PAGE_FULL_SCREEN,
  STORAGE_SIDE_BAR_COMPACT,
} from '~~/types/storage'

export const useLayout = () => {
  const route = useRoute()

  const isFullScreen = useLocalStorage<boolean>(STORAGE_PAGE_FULL_SCREEN, false)
  const canShowFullScreen = computed<boolean>(() =>
    ['/price-monitor'].includes(route.path),
  )
  const fullScreenToggle = (): void => {
    isFullScreen.value = canShowFullScreen.value && !isFullScreen.value
  }

  const isSidebarCompact = useLocalStorage<boolean>(
    STORAGE_SIDE_BAR_COMPACT,
    false,
  )

  return {
    isFullScreen,
    canShowFullScreen,
    fullScreenToggle,

    isSidebarCompact,
  }
}
